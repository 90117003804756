import React from "react";

import { useState } from 'react';
import { encrypt, receiveIV} from '../encryption'

export function SendScores() {
	const [FormContent,setFormContent] = useState({
		wallet: "",
		game: "",
		score: "",
		encrypted: "", 
		raw: "", 
		iv:""

	});

	const tempAlert = (msg) => {
		const duration = 2000
		var el = document.createElement("div");
		el.setAttribute("style","position:absolute;top:50%;left:50%;background-color:blue;padding:5px;");
		el.innerHTML = msg;
		setTimeout(function(){
			el.parentNode.removeChild(el);
	 	},duration);
		document.body.appendChild(el);
	}

	const handleChange = (event) => {
		const {name,value}  = event.target
		setFormContent((prevFormData) => ({...prevFormData, [name]:value}));
	  }

	const handleCopy = async(event) => {
		navigator.clipboard.writeText(FormContent.encrypted)
		tempAlert("text copied")
	}
	const handleSubmit = async (event) => {
		event.preventDefault();
		setFormContent((prevContent) => ({
			...prevContent,
			encrypted: "",
			raw: "",
			iv: ""
		}))
		let newInput = {
			wallet:event.currentTarget.elements.wallet.value,
			game:event.currentTarget.elements.game.value,
			score:event.currentTarget.elements.score.value,
			difficulty:event.currentTarget.elements.difficulty.value,
		}
		newInput = JSON.stringify(newInput)
		let IV = await receiveIV()
		console.log("IV2:",IV)
		let encrypted = await encrypt(newInput,IV)
		setFormContent((prevContent) => ({
			...prevContent,
			encrypted: encrypted + IV,
			raw: newInput,
			iv: IV
		}))
	}

	return (
		<form onSubmit={handleSubmit}>
		<div className="SubmitScores">
			<div className="form">
				<label>Wallet: </label><select className="wallet" name="wallet">
					<option value="0x92d662f254ad297bb6b454cb8eac439d8166f35a">Dr Grumpy</option>
					<option value="0x584587833419b5b69783fc5c881cf4e38f06e754">Winner #1768</option>
				</select><br/>

				<label>Game: </label><select className="game" name="game">
					<option value="Gem Miner">Gem Miner</option>
					<option value="Crypto Slash">Crypto Slash</option>
					<option value="Moonshot">Moonshot</option>
					<option value="Solitaire">Solitaire</option>
					<option value="Coin Catcher">Coin Catcher</option>
					<option value="TestGame">TestGame</option>
				</select><br/>
				<label>Difficulty: </label><select className="difficulty" name="difficulty">
					<option value="1">free</option>
					<option value="2">easy</option>
					<option value="3">difficult</option>
					<option value="4">masterclass</option>
				</select><br/>
				<label>Score: </label><input type="text" className="score" name="score" value={FormContent.score}  onChange={handleChange}/> <br/>

				<br/>
				<button className="submit" type="submit">Encrypt</button>
			</div>
			<div className="raw">
				<textarea className="rawinput" value={FormContent.raw}/>
				IV: <input className="iv" value={FormContent.iv}/>
			</div>
			<div className="encrypted">
			<div className="copy" onClick={handleCopy}>--copy--</div>
			<textarea className="encrypted" value={FormContent.encrypted}/>
			</div>
	  </div>
	  </form>
	);
  };

