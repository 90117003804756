import React from 'react';
import gamedetails from './games.json';

import './index.css'
import { Encryptform } from './Encryptform'
import { P2Eadmin } from './P2Eadmin'

export function Play2earn() {
  const GameDetailsItems = gamedetails.map((game) => {
    if (game.active) {
      return (
        <div key={game.title & "_cont"} className="game">
        <div key={game.title & "_title"} className="title active" ><a href={game.folder}>{game.title}</a></div>
        <p>{game.description}</p>
      </div>
      )
    }
    else {
      return (
        <div key={game.title & "_cont"} className="game">
        <div key={game.title & "_cont"} className="title inactive">{game.title}</div>
        <p>{game.description}</p>
      </div>
      )
    };
  });

  //<Encryptme/>
  //<Userdetails/>
  return (
    <div key="container">
      <Encryptform/>
      <div key="gamecontainer">
        {GameDetailsItems}
      </div>
      <P2Eadmin/>
    </div>
    )
  }
