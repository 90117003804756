import React from 'react';

import { useState } from 'react';
import './index.css'
import {encrypt, decrypt} from '../encryption';
import axios from "axios";

const RestApi = {
  BaseURL : 'https://rest.hodlbot.org/api/game/',
  User : 'Test1@hodlbot.org',
  Password : 'test!pass',
}


export function Encryptme() {
	
  const [FormData,setFormData] = useState({secret: "", key: "", hidden: "", hidden2: "", clear: ""});
  
  const handleChange = (event) => {
    const {name,value}  = event.target
    setFormData((prevFormData) => ({...prevFormData, [name]:value}));
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (event.nativeEvent.submitter.name==="fetchkey") {

      axios
      .get(RestApi.BaseURL + "key")
      .then((res) => setFormData((prevFormData) => ({...prevFormData, key:res.data.status})))
      .catch(err => {
       console.log(err.message);
      });      
    }
    else if (event.nativeEvent.submitter.name==="encrypt") {
      const encrypted = await encrypt(FormData.secret, FormData.key);
      setFormData((prevFormData) => ({...prevFormData, hidden:encrypted, hidden2:encrypted}));
    }
    else if (event.nativeEvent.submitter.name==="decrypt") {
      const result = await decrypt(FormData.hidden2,FormData.key)
      setFormData((prevFormData) => ({...prevFormData, clear:result}));
    }
    };

  return (
		<form onSubmit={handleSubmit}>
    <div className="encryption">
		<p>public key: <input name="key" className="lockedshort" readOnly value={FormData.key}/><button type="submit" name="fetchkey">Request</button></p>
    <p>Something you want to encrypt: <input id="secret" name="secret" style={{width: "330px"}} value={FormData.secret} onChange={handleChange}/></p>
		<button type="submit" name="encrypt">Encrypt</button>		
		<p>encrypted: <input name="hidden" size="80" className="locked" readOnly value={FormData.hidden + FormData.key}/></p>
    </div>

    <div className="decryption">
    <p>Something you want to decrypt: <input id="hidden2" name="hidden2" style={{width: "330px"}} value={FormData.hidden2} onChange={handleChange}/></p>
		<p>public key: <input id="key" name="key" value={FormData.key}  style={{width: "330px"}} onChange={handleChange}/></p>
		<button type="submit" name="decrypt" >Decrypt</button>		
		<p>clear text: <input name="cleartext" className="locked" readOnly value={FormData.clear}/></p>
    </div>
    </form>
  );
}
