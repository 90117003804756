import { useAccount } from 'wagmi'
import { useWeb3Modal } from '@web3modal/wagmi/react'
import { Userdetails } from './Userdetails'

export function Connected() {
	
  //const [WalletDetails,setWalletDetails] = useState({wallet: "", HODL: "", HH: "", HODLX: ""});
	console.log("Connected is executed!")
	const { open } = useWeb3Modal()
	const { address } = useAccount()


	const addressShort = address.slice(0,5) + "..." + address.slice(-3)

	
	return (
		<div className="userdetails">
		  <button onClick={() => open()}>LOGOUT</button>
		  <Userdetails/>
		</div>
	)
}