import React from "react";

import { useState } from 'react';
import { encrypt, receiveIV} from '../encryption'

export function Login() {
	const [FormContent,setFormContent] = useState({
		wallet: "",
		walletraw:"",
		encrypted: "", 
		raw: "", 
		iv:""

	});

	const tempAlert = (msg) => {
		const duration = 2000
		var el = document.createElement("div");
		el.setAttribute("style","position:absolute;top:50%;left:50%;background-color:blue;padding:5px;");
		el.innerHTML = msg;
		setTimeout(function(){
			el.parentNode.removeChild(el);
	 	},duration);
		document.body.appendChild(el);
	}

	const handleChange = (event) => {
		const {name,value}  = event.target
		setFormContent((prevFormData) => ({...prevFormData, [name]:value}));
	  }

	const handleCopy = async(event) => {
		navigator.clipboard.writeText(FormContent.encrypted)
		tempAlert("text copied")
	}
	const handleSubmit = async (event) => {
		event.preventDefault();
		setFormContent((prevContent) => ({
			...prevContent,
			encrypted: "",
			raw: "",
			iv: ""
		}))
		let newInput
		if (event.currentTarget.elements.walletraw.value) {
			newInput = {
				wallet:event.currentTarget.elements.walletraw.value,
			}
		}
		else {
			newInput = {
				wallet:event.currentTarget.elements.wallet.value,
			}
		}
		newInput = JSON.stringify(newInput)
		let IV = await receiveIV()
		console.log("IV2:",IV)
		let encrypted = await encrypt(newInput,IV)
		setFormContent((prevContent) => ({
			...prevContent,
			encrypted: encrypted + IV,
			raw: newInput,
			iv: IV
		}))
	}

	return (
		<form onSubmit={handleSubmit}>
		<div className="Login">
			<div className="form">
				<label>Wallet (raw): </label><input className="walletraw" name="walletraw" value={FormContent.walletraw} onChange={handleChange}/><br/>
				or <br/>
				<label>Wallet: </label><select className="wallet" name="wallet">
					<option value="0x92d662f254ad297bb6b454cb8eac439d8166f35a">Dr Grumpy</option>
					<option value="0x584587833419b5b69783fc5c881cf4e38f06e754">Winner #1768</option>
				</select><br/>
				<br/>
				<button className="submit" type="submit">Encrypt</button>
			</div>
			<div className="raw">
				<textarea className="rawinput" value={FormContent.raw}/>
				IV: <input className="iv" value={FormContent.iv}/>
			</div>
			<div className="encrypted">
			<div className="copy" onClick={handleCopy}>--copy--</div>
			<textarea className="encrypted" value={FormContent.encrypted}/>
			</div>
	  </div>
	  </form>
	);
  };

