import secrets from './secrets.json';
import axios from "axios";

const RestApi = 'https://rest.hodlbot.org/api/game/'

export const encrypt = async function (message, iv, config = 0) {
	//const iv = generateString(16)
	const salt = secrets[config].Salt
	const password = secrets[config].Password
	const ivcorrected = stringToUint8Array(iv);
	const key = await deriveKeyFromPassphrase(password, salt, 10000, 256);
	let encrypt = await encryptWithKey(key, ivcorrected, message);
	return encrypt

  };

export const decrypt = async function (message, iv, config = 0){
	const salt = secrets[config].Salt
	const password = secrets[config].Password
	const ivcorrected = stringToUint8Array(iv);
	const key = await deriveKeyFromPassphrase(password, salt, 10000, 256);
	let decrypt = await decryptWithKey(key, ivcorrected, message);
	return decrypt
  };

export const receiveIV = async function() {
	let IV
	await axios
      .get(RestApi + "key")
      .then((res) => {IV = res.data.status})
      .catch(err => {
       console.log(err.message);
      });
	console.log("IV:",IV)
	console.log(IV)
	return IV
}

async function deriveKeyFromPassphrase(passphrase, salt, iterations, keyLength) {
	const encoder = new TextEncoder();
	const passphraseBuffer = encoder.encode(passphrase);
	const derivedKey = await crypto.subtle.importKey(
	  'raw',
	  passphraseBuffer,
	  { name: 'PBKDF2' },
	  false,
	  ['deriveKey']
	);
	const saltBuffer = encoder.encode(salt);
	const key = await crypto.subtle.deriveKey(
	  {
		name: 'PBKDF2',
		salt: saltBuffer,
		iterations: iterations,
		hash: 'SHA-256', // You can choose a different hashing algorithm
	  },
	  derivedKey,
	  { name: 'AES-CBC', length: keyLength },
	  true,
	  ['encrypt', 'decrypt']
	);
	return key;
  };

  async function encryptWithKey(key, iv, plaintext) {
	const encoder = new TextEncoder();
	const data = encoder.encode(plaintext);
	const encryptedData = await crypto.subtle.encrypt(
	  {
		name: 'AES-CBC',
		iv: iv,
	  },
	  key,
	  data
	);
	
	let result = {
	  iv: Array.from(new Uint8Array(iv)).map((byte) => byte.toString(16).padStart(2, '0')).join(''),
	  ciphertext: Array.from(new Uint8Array(encryptedData)).map((byte) => byte.toString(16).padStart(2, '0')).join(''),
	};
	return result.ciphertext;
  }
  
  async function decryptWithKey(key, iv, encryptedData) {
	const ciphertext = Uint8Array.from(
	  encryptedData.match(/.{2}/g).map((byte) => parseInt(byte, 16))
	);
	const decryptedData = await crypto.subtle.decrypt(
	  {
		name: 'AES-CBC',
		iv: iv,
	  },
	  key,
	  ciphertext
	);
	const decoder = new TextDecoder();
	return decoder.decode(decryptedData);
  }

  function stringToUint8Array(inputString) {
	const length = inputString.length;
	const uint8Array = new Uint8Array(length);
	for (let i = 0; i < length; i++) {
	  uint8Array[i] = inputString.charCodeAt(i);
	}
	return uint8Array;
  }