import './App.css';

import React from 'react';

//import { Web3Provider } from '@ethersproject/providers';
//import { Web3ReactProvider } from '@web3-react/core';


import { Play2earn } from './components/Play2earn';
import { WagmiConnect } from './components/Play2earn/WagmiConnect';
import { WagmiConfig } from 'wagmi'
import { createWeb3Modal, defaultWagmiConfig } from '@web3modal/wagmi/react'
import { bsc, bscTestnet } from 'viem/chains'
import { CryptoCom } from './components/Trending'
//<Web3ReactProvider getLibrary={(provider) => new Web3Provider(provider)}>
//</Web3ReactProvider>


// 1. Get projectId
const projectId = '13cf50e07efdab2d089fa8e8ab72ab93'
const metadata = {
  name: 'Web3Modal',
  description: 'Web3Modal Example',
  url: 'https://web3modal.com',
  icons: ['https://avatars.githubusercontent.com/u/37784886']
}
const chains = [bsc, bscTestnet]
const wagmiConfig = defaultWagmiConfig({ chains, projectId, metadata })
createWeb3Modal({ wagmiConfig, projectId, chains })


const App = () => (
      <div>
      <WagmiConfig config={wagmiConfig}>
            <WagmiConnect/>
      </WagmiConfig>
      <Play2earn/>
      
      <CryptoCom/>
      </div>
);

export default App;
