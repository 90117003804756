import React from 'react';

import { useState } from 'react';
import './index.css'
//import {encrypt, decrypt} from '../encryption';
//import axios from "axios";
import { GetAsset } from './GetAsset';
import { AddAsset } from './AddAsset';
import { ConsumeAsset } from './ConsumeAsset';
import { SendScores } from './SendScores'
import { Login } from './Login'
import { ChangeUser } from './ChangeUser'
import { Encryptme } from '../Encryptme'

export function Encryptform() {

	const [activeForm,setActiveForm] = useState("ast_tab1")

	const determineClassName = (target) => {
		const category = activeForm.substring(0,activeForm.indexOf('_'))
		//console.log("DEBUG target:", target)
		//console.log("DEBUG category:", category)
		//console.log("DEBUG active:", activeForm)
		//console.log("DEBUG ------------------------------------------")
		//handle category buttons first
		if (target.startsWith('category') & target.endsWith(category))
				return target + " active"
		else if (target.startsWith('category'))
				return target
		//handle details button		
		if (activeForm === target)
			return target + " active"
		else if (target.startsWith(category))
			return target
		else
			return "hidden"
	}

	return (
		<div className="Tabs">
			<ul className="nav1">
				<li className={determineClassName('category_encrypt')} onClick={() =>setActiveForm('encrypt_encrypt')}>Encryption</li>
				<li className={determineClassName('category_game')} onClick={() =>setActiveForm('game_login')}>Game endpoint</li>
				<li className={determineClassName('category_ast')} onClick={() =>setActiveForm('ast_tab1')}>Asset endpoint</li>
			</ul>
			<ul className="nav">
				<li className={determineClassName('encrypt_encrypt')} onClick={() =>setActiveForm('encrypt_encrypt')}>Encrypt</li>
				<li className={determineClassName('encrypt_decrypt')} onClick={() =>setActiveForm('encrypt_decrypt')}>Decrypt</li>
				<li className={determineClassName('encrypt_key')} onClick={() =>setActiveForm('encrypt_key')}>Generate Key</li>
				
				
				<li className={determineClassName('game_login')} onClick={() =>setActiveForm('game_login')}>Login</li>
				<li className={determineClassName('game_score')} onClick={() =>setActiveForm('game_score')}>Submit Score</li>
				<li className={determineClassName('game_changeuser')} onClick={() =>setActiveForm('game_changeuser')}>Change User</li>

				<li className={determineClassName('ast_tab1')} onClick={() =>setActiveForm('ast_tab1')}>Check Asset</li>
				<li className={determineClassName('ast_tab2')} onClick={() =>setActiveForm('ast_tab2')}>Add Asset</li>
				<li className={determineClassName('ast_tab3')} onClick={() =>setActiveForm('ast_tab3')}>Consume Asset</li>
			</ul>
			<div className="outlet">
				{activeForm === "game_login" ? <Login /> : ""}
				{activeForm === "game_score" ? <SendScores /> : ""}
				{activeForm === "game_changeuser" ? <ChangeUser /> : ""}
				{activeForm === "ast_tab1" ? <GetAsset /> : ""}
				{activeForm === "ast_tab2" ? <AddAsset /> : ""}
				{activeForm === "ast_tab3" ? <ConsumeAsset /> : ""}
				{activeForm === "encrypt_encrypt" ? <Encryptme />: ""}
			</div>
		</div>
	);

};