import { useAccount } from 'wagmi'
import { Disconnected } from './Disconnected'
import { Connected } from './Connected'

export function WagmiConnect() {
  const { isConnecting, isDisconnected } = useAccount()
  if (isDisconnected) return <Disconnected />
  if (isConnecting) {
    return (
      <div>
        connecting...
      </div>
    )
  }
  return <Connected />
}