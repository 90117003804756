// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

div {
  margin: 0px;
  padding: 0px;
}
.userdetails {
  background-color: lightblue ;
  border: none ;
  margin: 0px;
  padding-top: 0px;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 0px;
  width: 100%;
}

.restdetails {
  font-size: 12px;
  font-style: italic;
  background-color: rgb(255, 214, 214) ;
  border: none ;
  margin: 0px;
  padding-top: 0px;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 0px;
  width: 100%;
}

.game {
  width: 250px;  
  background-color: lightgray;
  float: left;
  margin: 15px;

  .title.active {
    background-color: aliceblue;
    color: darkblue;

  }

  .title.inactive {
    background-color: darkgray;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
`, "",{"version":3,"sources":["webpack://./src/components/Play2earn/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE,WAAW;EACX,YAAY;AACd;AACA;EACE,4BAA4B;EAC5B,aAAa;EACb,WAAW;EACX,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;EAClB,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,eAAe;EACf,kBAAkB;EAClB,qCAAqC;EACrC,aAAa;EACb,WAAW;EACX,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;EAClB,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,2BAA2B;EAC3B,WAAW;EACX,YAAY;;EAEZ;IACE,2BAA2B;IAC3B,eAAe;;EAEjB;;EAEA;IACE,0BAA0B;EAC5B;AACF;;AAEA;EACE;aACW;AACb","sourcesContent":["body {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ndiv {\n  margin: 0px;\n  padding: 0px;\n}\n.userdetails {\n  background-color: lightblue ;\n  border: none ;\n  margin: 0px;\n  padding-top: 0px;\n  padding-left: 5px;\n  padding-right: 5px;\n  padding-bottom: 0px;\n  width: 100%;\n}\n\n.restdetails {\n  font-size: 12px;\n  font-style: italic;\n  background-color: rgb(255, 214, 214) ;\n  border: none ;\n  margin: 0px;\n  padding-top: 0px;\n  padding-left: 5px;\n  padding-right: 5px;\n  padding-bottom: 0px;\n  width: 100%;\n}\n\n.game {\n  width: 250px;  \n  background-color: lightgray;\n  float: left;\n  margin: 15px;\n\n  .title.active {\n    background-color: aliceblue;\n    color: darkblue;\n\n  }\n\n  .title.inactive {\n    background-color: darkgray;\n  }\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
