// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.encryption {
	width: 350px;
	border: 1px solid grey;
	border-radius: 20px;
	padding: 15px 10px 10px 10px;
	margin: 10px;
	top: 20%;
	background-color: rgb(255, 155, 155);
}

.locked {
	width:330px;
	background-color: rgb(195, 195, 195);
	pointer-events:fill;
	border: none;
}

.lockedshort {
	width:270px;
	background-color: rgb(195, 195, 195);
	pointer-events:fill;
	border: none;
}

.decryption {
	width: 350px;
	border: 1px solid grey;
	border-radius: 20px;
	padding: 15px 10px 10px 10px;
	margin: 10px;
	top: 20%;
	background-color: rgb(255, 245, 155);
}`, "",{"version":3,"sources":["webpack://./src/components/Play2earn/Encryptme/index.css"],"names":[],"mappings":"AAAA;CACC,YAAY;CACZ,sBAAsB;CACtB,mBAAmB;CACnB,4BAA4B;CAC5B,YAAY;CACZ,QAAQ;CACR,oCAAoC;AACrC;;AAEA;CACC,WAAW;CACX,oCAAoC;CACpC,mBAAmB;CACnB,YAAY;AACb;;AAEA;CACC,WAAW;CACX,oCAAoC;CACpC,mBAAmB;CACnB,YAAY;AACb;;AAEA;CACC,YAAY;CACZ,sBAAsB;CACtB,mBAAmB;CACnB,4BAA4B;CAC5B,YAAY;CACZ,QAAQ;CACR,oCAAoC;AACrC","sourcesContent":[".encryption {\n\twidth: 350px;\n\tborder: 1px solid grey;\n\tborder-radius: 20px;\n\tpadding: 15px 10px 10px 10px;\n\tmargin: 10px;\n\ttop: 20%;\n\tbackground-color: rgb(255, 155, 155);\n}\n\n.locked {\n\twidth:330px;\n\tbackground-color: rgb(195, 195, 195);\n\tpointer-events:fill;\n\tborder: none;\n}\n\n.lockedshort {\n\twidth:270px;\n\tbackground-color: rgb(195, 195, 195);\n\tpointer-events:fill;\n\tborder: none;\n}\n\n.decryption {\n\twidth: 350px;\n\tborder: 1px solid grey;\n\tborder-radius: 20px;\n\tpadding: 15px 10px 10px 10px;\n\tmargin: 10px;\n\ttop: 20%;\n\tbackground-color: rgb(255, 245, 155);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
