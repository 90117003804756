import { useAccount } from "wagmi";
import { useState, useEffect } from 'react';
import { receiveIV, encrypt} from '../encryption'
import axios from 'axios'

export function Userdetails() {
	const { address } = useAccount()

	const RestApi = 'https://rest.hodlbot.org/api/game/';
	const [LoginResponse, setLoginResponse] = useState({
		Success: "...",
		Status: "not logged in",
		Wallet: "n/a",
	  	WalletShort: "n/a",
	  	Username: "n/a",
	  	State: 0,
	  	Debug: false,
	  });

	function setCookie(content, pubkey) {
		const d = new Date();
		d.setTime(d.getTime() + (24*60*60*1000)); //1 day
		let expires = "expires="+ d.toUTCString();
		document.cookie = "k=" +  content + pubkey + ";" + expires + ";path=/;SameSite=Strict";
		//document.cookie = "ue=" + username2 + ";" + expires + ";path=/;SameSite=Strict"; 
		//document.cookie = "we=" + wallet2 + ";" + expires + ";path=/;SameSite=Strict"; 
		//document.cookie = "pk=" + pubkey + ";" + expires + ";path=/;SameSite=Strict"; 
		//document.cookie = "wallet=" + wallet + ";" + expires + ";path=/;SameSite=Strict";
		//document.cookie = "username=" + username + ";" + expires + ";path=/;SameSite=Strict";
	};


	
	useEffect( () => {
		async function getUserdetails() {
			if (address && LoginResponse.State ===0) { //wallet connected but no userdetails received yet
				var publicKey = await receiveIV()
				console.log("2) Key received as:", publicKey)
				let accountEncrypted = {
				  'wallet': address,
				  'game': 8,
				  'version': 'testfrontend',
				}
				accountEncrypted = JSON.stringify(accountEncrypted)
				accountEncrypted = await encrypt(accountEncrypted, publicKey)
				let loginheaders = {
				  headers: {
					'k': accountEncrypted + publicKey,
				  }
				}
				const response = await axios.post(RestApi + "login", null, loginheaders)
				if (!response.data.success) { //login failed
				  setLoginResponse({...LoginResponse, Debug: JSON.stringify(response.data), State: 'ERROR', Status: response.data.error, Success: false})
				  return
				}
				let cookieContent = {
					wallet: address,
					user: response.data.username
				}
				cookieContent = JSON.stringify(cookieContent)
				publicKey = await receiveIV()
				let cookie = await encrypt(cookieContent,publicKey)
				setCookie(cookie,publicKey)//response.data.username, address,response.data.username, publicKey)
				//setCookie(cookie,response.data.username, address,response.data.username, publicKey)
				setLoginResponse({
					...LoginResponse, 
					State:1, 
					Debug: JSON.stringify(response.data), 
					Status: response.data.status, 
					Success: true,
					Username: response.data.username,
					Wallet: address,
					WalletShort: address.slice(0,5) + "..." + address.slice(-3)
				})
			}
		}
		getUserdetails();

	},[])



	return (
		<div className = "userinfo">
			<ul className="udUL">
				<li className="udUsername">Username: {LoginResponse.Username}</li>
	        	<li className="udStatus">Status: {LoginResponse.Status}</li>
	        	<li className="udWalletShort">Wallet: {LoginResponse.WalletShort}</li>
      		</ul>
		</div>
	)
}