import React from "react";

import { useState } from 'react';
import { encrypt, receiveIV} from '../encryption'

export function GetAsset() {
	const [FormContent,setFormContent] = useState({
		wallet: "",
		source: "",
		asset: "",
		encrypted: "", 
		raw: "", 
		iv:""

	});

	const handleCopy = async(event) => {
		navigator.clipboard.writeText(FormContent.encrypted)
		alert("encrypted text copied")
	}
	const handleSubmit = async (event) => {
		event.preventDefault();
		console.log("handleSubmit executed!")
		let newInput = {
			wallet:event.currentTarget.elements.wallet.value,
			asset:event.currentTarget.elements.asset.value,
		}
		newInput = JSON.stringify(newInput)
		let IV = await receiveIV()
		console.log("IV2:",IV)
		let encrypted = await encrypt(newInput,IV)
		setFormContent((prevContent) => ({
			...prevContent,
			encrypted: encrypted + IV,
			raw: newInput,
			iv: IV
		}))
	}

	return (
		<form onSubmit={handleSubmit}>
		<div className="GetAsset">
			<div className="form">
				<label>Wallet: </label><select className="wallet" name="wallet">
					<option value="0x92d662f254ad297bb6b454cb8eac439d8166f35a">Dr Grumpy</option>
					<option value="0x584587833419b5b69783fc5c881cf4e38f06e754">Winner #1768</option>
				</select><br/>

				<label>Asset: </label><select className="asset" name="asset">
					<option value="energy">Energy</option>
					<option value="lootbox">Lootbox</option>
				</select><br/>
				<br/>
				<button className="submit" type="submit">Encrypt</button>
			</div>
			<div className="raw">
				<textarea className="rawinput" value={FormContent.raw}/>
				IV: <input className="iv" value={FormContent.iv}/>
			</div>
			<div className="encrypted">
			<div className="copy" onClick={handleCopy}>--copy--</div>
			<textarea className="encrypted" value={FormContent.encrypted}/>
			</div>
	  </div>
	  </form>
	);
  };

