import React from 'react';

import { useState } from 'react';
import './index.css'
//import { GetAsset } from './GetAsset';

export function P2Eadmin() {

	const [Collapsestate,setCollapsestate] = useState({collapse: true, navigation: "", view: "0"})

	const contentcollapsed = <div onClick={() =>setCollapsestate({...Collapsestate, collapse: false})}><h3><img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTHJG3Jdf8Lm8wKSVgKw3O94pdS_M6jjPnxzqhPD2w9mhXZEw_62--L9JVykqOaeAI6C7M&usqp=CAU" height="30"/> Admin Panel (click to open)</h3></div>
	let menue = (
		<div className="navigation">
			<p onClick={() =>setCollapsestate({...Collapsestate, navigation: "Season"})}>Seasons</p>
			<p onClick={() =>setCollapsestate({...Collapsestate, navigation: "Highscore"})}>Highscores</p>
			<p onClick={() =>setCollapsestate({...Collapsestate, navigation: ""})}>...</p>
		</div>
	)
	
	const content = () => {
		switch (Collapsestate.navigation) {
			case "Season" 		: return (<div>Current active Seasons:...</div>)
			case "Highscore" 	: return (<div>Current Highscores... </div>)
			default				: return (<div>no navigation selected </div>)
		}
	}

	return (
		<div className="p2eadmin">
			{Collapsestate.collapse ? contentcollapsed : ""}
			{!Collapsestate.collapse ? <div onClick={() =>setCollapsestate({...Collapsestate,collapse:true})}><h3>Admin Panel (click to collapse)</h3></div> : ""}
			{!Collapsestate.collapse ? menue : ""}
			{!Collapsestate.collapse ? content() : ""}
		</div>
	);
};