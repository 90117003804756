import axios from 'axios';
import React from 'react';
import { useState } from 'react';

import { AbiConstructorNotFoundError } from 'viem';


const baseURL = "https://price-api.crypto.com/popular/v1/visit"
//const params = {type:1,key:4558} //4558 = HODL


export function CryptoCom() {
	const [count, setCount] = React.useState(null)
	const [reload, setReload] = React.useState(0)
	React.useEffect(() => {
		axios.post(baseURL, {type:1,key:4558})
		.then((res) => {
			setCount(res.data.data)
		})
		.then((res) => {
			if (reload) {
				setReload(reload+1)
			}
		})
		.catch(err => console.log(err.message));
	},[reload]);

  	return (
	    <div key="container">
    	  	<div key="refreshcontainer">
				<p onClick={() =>setReload(reload?0:1)}> - reload ({reload?"on":"off"})- </p> current number of HODL visits @ <a href="https://crypto.com/price/hodl">crypto.com</a>: {count}
    		</div>
    	</div>
    )
  }

  //			<p onClick={() =>setReload(reload?0:1)}> - reload ({reload})- </p> current number of HODL visits @ <a href="https://crypto.com/price/hodl">crypto.com</a>: {count.qty}
